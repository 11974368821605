<template>
  <div>
    <v-container
      v-if="loaderSkeleton"
      class="v-container-fluid"
      fluid
    >
      <v-row class="mb-5">
        <v-col
          cols="12"
          lg="4"
          md="4"
          v-for="(item, index) in 6"
          :key="index"
        >
          <v-sheet
            color="grey lighten-4"
            class="pa-0"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-else
      class="v-container-fluid"
      fluid
    >
      <v-row v-if="aWarehouses.length > 0">
        <v-col
          v-for="(item, index) in aWarehouses"
          :key="index"
          cols="12"
          xl="4"
          lg="4"
          md="6"
          sm="12"
        >
          <div
            class="content-card"
            :style="(item.oWarehouse.bMainWarehouse) ? { border:'2px solid #151d27' } : { border:'2px solid #ffffff' }"
          >
            <!-- <warehouse-menu-layout
              :texts="texts"
              :arr="item"
            /> -->
            <v-btn
              v-if="item.oWarehouse.bMainWarehouse"
              class="button-star"
              icon
            >
              <v-icon color="#F4EA77">mdi-star</v-icon>
            </v-btn>
            <v-btn
              v-else
              class="button-star"
              icon
              @click="selectFavorite(item.sCustomerWarehouseId)"
            >
              <v-icon color="#D8D8D8">mdi-star</v-icon>
            </v-btn>
            <div class="display-flex align-items-center">
              <div
                style="width: 100%;"
                class=""
              >
                <p class="text-title mon-medium">
                  {{ item.oWarehouse.sName }}
                  <v-icon
                    v-if="item.oWarehouse.bMainWarehouse == true"
                    color="#039365"
                    size="20px"
                  >
                    mdi-check-decagram
                  </v-icon>
                </p>
                <div class="display-flex align-items-flex-start mb-1">
                  <v-icon
                    color="#00000050"
                    size="16px"
                    class="mr-1"
                  >
                    mdi-map-marker
                  </v-icon>
                  <p
                    v-if="restantHeight"
                    :style="{ height: `${restantHeight}px` }"
                    class="text-address mon-regular"
                  >
                    {{ 
                      (item.oLocation.sLocationOptionalAddress == '' || item.oLocation.sLocationOptionalAddress == null)
                      ? item.oLocation.sAddress + ', ' + item.oLocation.sZipCode + ' ' + item.oLocation.sCity +', ' + item.oLocation.sStateName
                      : item.oLocation.sAddress + ', ' + item.oLocation.sLocationOptionalAddress + ', ' + item.oLocation.sZipCode + ' ' + item.oLocation.sCity + ', ' + item.oLocation.sStateName
                     }}
                  </p>
                </div>
                <div class="display-flex align-items-center">
                  <v-icon
                    color="#00000050"
                    size="16px"
                    class="mr-1"
                  >
                    mdi-phone-outline
                  </v-icon>
                  <p class="text-phone mon-regular">{{ item.oWarehouse.sPhoneNumber }}</p>
                </div>
                <div class="text-right">
                  <v-btn
                    :to="`/warehouse/edit/${item.sCustomerWarehouseId}`"
                    elevation="0"
                    class="button-view-detail mon-bold mt-2"
                  >
                    {{ texts.warehouse.wiewDetail }}
                    <v-icon
                      class="ml-1"
                      color="#283C4D"
                      size="14px"
                    >
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <div
            v-if="aWarehouses.length <= 3"
            style="height: 150px"
          />
        </v-col>
        <v-col cols="12">
          <div class="display-flex align-items-center justify-content-center">
            <div class="pagination-styles">
              <v-pagination
                v-model="iCurrentPage"
                :length="lengthPage"
                color="#FFC556"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
              ></v-pagination>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <div class="text-empty display-flex align-items-center justify-content-center mon-regular">
            {{ texts.warehouse.emptyText }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "WarehouseContentLayout",
  data() {
    return {
      //VARIABLES
      aWarehouses: [],
      restantHeight: 0,
      lengthPage: 0,
      iCurrentPage: 1,
      loaderSkeleton: true,
    };
  },
  beforeMount() {
    this.getWarehouses();
  },
  updated() {
    this.resizeDiv();
  },
  created() {
    window.addEventListener("resize", this.resizeDiv);
    this.resizeDiv();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeDiv);
  },
  methods: {
    selectFavorite: function (id) {
      const payload = {};

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${id}`,
        payload,
        config
      )
        .then((response) => {
          this.$store.commit("refresher", true);
          this.mixSuccess(response.data.message);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getWarehouses: function () {
      this.loaderSkeleton = true;

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: 12,
          },
        }
      )
        .then((response) => {
          this.loaderSkeleton = false;

          this.aWarehouses = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    resizeDiv: function () {
      var contentHeight = document.getElementsByClassName("text-address");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeight = max;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getWarehouses();
    },
  },
};
</script>

<style scoped>
.text-empty {
  text-align: center;
  font-size: 20px;
  color: #c1c1c3;
  width: 100%;
  height: 450px;
}

.dots-vertical {
  position: absolute;
  right: 10px;
  top: 10px;
}

.text-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.text-address {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 0px;
}

.text-phone {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 0px;
}

.button-view-detail {
  /* border: 1px solid #283c4d; */
  border-radius: 10px;
  opacity: 1;
  text-transform: initial;
  font-size: 12px;
  /* background-color: #ffffff !important; */
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  height: 35px !important;
}

.content-logo {
  width: 170px;
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(224deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  border-radius: 19px;
  text-align: center;
  font-size: 51px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

/* .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 19px;
  }
} */

.content-card {
  border: 3px solid #ffffff;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding: 15px;
  border-radius: 5px;
  position: relative;
}

.button-star {
  position: absolute;
  top: 5px;
  right: 5px;
}

@media (max-width: 600px) {
  .button-view-detail {
    margin-top: 25px !important;
    width: 100%;
  }
}
</style>